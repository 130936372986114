import styled from 'styled-components/macro'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ReferenceCardExtraContentProps } from '../../../../../interfaces/references'
import Typography from '../../../../common/Typography'

export default function ExtraContentDesktop(
  props: ReferenceCardExtraContentProps
) {
  const firstImageWidth = 430
  const firstImageHeight = 280
  const secondImageWidth = 460
  const secondImageHeight = 518
  return (
    <StyledExtraContentContainer>
      {props.reference.video_link && (
        <StyledReferenceVideo>
          <iframe
          width="450"
          height="253"
          src={props.reference.video_link}
          title={props.reference.video_title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        </StyledReferenceVideo>
      )}
      {props.reference.testimonial && (
        <StyledTestimonialContainer>
          <StyledTestimonial type="quote" color={props.reference.chip_color}>
            <StyledQuotationMark>“</StyledQuotationMark>
            {props.reference.testimonial}
          </StyledTestimonial>
          <StyledTestimonialSource type="copyBig">
            {props.reference.testimonial_source}
          </StyledTestimonialSource>
        </StyledTestimonialContainer>
      )}

      <StyledLongTextContainer>
        <StyledTextAndImageContainer
          rowDirection={
            props.reference.cardIndex % 2 === 0 ? 'row-reverse' : 'row'
          }
          isFirst
        >
          <StyledTextContainer isFirst>
            {props.reference.long_description_1s.map(longDescription1 => {
              return (
                <React.Fragment key={uuidv4()}>
                  <StyledTitle type="h6">
                    {longDescription1.title}
                    <br />
                  </StyledTitle>
                  <StyledText type="copyBig">
                    {longDescription1.text}
                  </StyledText>
                </React.Fragment>
              )
            })}
          </StyledTextContainer>
          <StyledImageContainer isFirst>
            <StyledImage
              src={props.reference.image1.localFile.publicURL}
              alt="Reference Image"
              loading="eager"
              width={firstImageWidth}
              height={firstImageHeight}
              isFirst
            />
          </StyledImageContainer>
        </StyledTextAndImageContainer>

        <StyledTextAndImageContainer
          isFirst={false}
          rowDirection={
            props.reference.cardIndex % 2 === 0 ? 'row' : 'row-reverse'
          }
        >
          <StyledTextContainer isFirst={false}>
            {props.reference.long_description_2s &&
              props.reference.long_description_2s.map(longDescription2 => {
                return (
                  <React.Fragment key={uuidv4()}>
                    <StyledTitle type="h6">
                      {longDescription2.title}
                      <br />
                    </StyledTitle>
                    <StyledText type="copyBig">
                      {longDescription2.text}
                    </StyledText>
                  </React.Fragment>
                )
              })}
            {props.reference.long_description_3s &&
              props.reference.long_description_3s.map(longDescription3 => {
                return (
                  <React.Fragment key={uuidv4()}>
                    <StyledTitle type="h6">
                      {longDescription3.title}
                      <br />
                    </StyledTitle>
                    <StyledText type="copyBig">
                      {longDescription3.text}
                    </StyledText>
                  </React.Fragment>
                )
              })}
          </StyledTextContainer>
          <StyledImageContainer isFirst={false}>
            {props.reference.image2 && (
              <StyledImage
                src={props.reference.image2.localFile.publicURL}
                alt="Reference Image"
                isFirst={false}
                width={secondImageWidth}
                height={secondImageHeight}
              />
            )}
            {props.reference.image3 && (
              <StyledImage
                src={props.reference.image3.localFile.publicURL}
                alt="Reference Image"
                isFirst={false}
                width={secondImageWidth}
                height={secondImageHeight}
              />
            )}
          </StyledImageContainer>
        </StyledTextAndImageContainer>
      </StyledLongTextContainer>
    </StyledExtraContentContainer>
  )
}

const StyledExtraContentContainer = styled.div`
  display: block;
  text-align: left;
`

const StyledTestimonialContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
`

const StyledTestimonial = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color};
  padding: 4rem 0 1rem;
  font-weight: 700;
`

const StyledTestimonialSource = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.darkGrey};
  padding: 0 0 6rem;
  font-weight: 700;
`

const StyledQuotationMark = styled.span`
  font-size: 8rem;
  position: absolute;
  top: 3rem;
  left: -6rem;
`

const StyledLongTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTextAndImageContainer = styled.div<{
  rowDirection: 'row' | 'row-reverse'
  isFirst: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ rowDirection }) => rowDirection};
  margin: ${({ isFirst }) => (isFirst ? '5rem 0 0' : '0')};
  gap: 2rem;
`

const StyledImageContainer = styled.div<{
  isFirst: boolean
}>`
  width: 50%;
  max-width: ${({ isFirst }) => (isFirst ? '45rem' : '47rem')};
`

const StyledImage = styled.img<{
  isFirst: boolean
  width: number
  height: number
}>`
  width: 100%;
  height: auto;
  aspect-ratio: ${({ width, height }) => width / height};
`

const StyledTextContainer = styled.div<{
  isFirst: boolean
}>`
  width: 50%;
  display: flex;
  flex-direction: column;
  max-width: ${({ isFirst }) => (isFirst ? '47rem' : '45rem')};
`

const StyledTitle = styled(Typography)`
  font-weight: 700;
  padding: 1rem 0;
`
const StyledText = styled(Typography)`
  padding: 2rem 0;
`

const StyledReferenceVideo = styled.div`
  text-align: center;
  margin: 8rem auto 6rem;
`
