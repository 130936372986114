import styled from 'styled-components/macro'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { v4 as uuidv4 } from 'uuid'

import { ReferenceCardExtraContentProps } from '../../../../../interfaces/references'
import Typography from '../../../../common/Typography'

export default function ExtraContent(props: ReferenceCardExtraContentProps) {
  return (
    <StyledReferenceCardContainer className="card">
      <StyledMainContentContainer>
        <StyledCardText>
          <StyledLogoImage
            src={props.reference.customer_logo.localFile.publicURL}
            alt={props.reference.logo_alt}
          />
          <StyledProductSlogan type="h4">
            {props.reference.product_slogan}
          </StyledProductSlogan>
          <StyledProductName type="overhead">
            {props.reference.product_name}
          </StyledProductName>
          <StyledCardImageMobileContainer>
            {props.reference.image_mobile ? (
              <StyledCardMainImageMobile
                src={props.reference.image_mobile.localFile.publicURL}
                alt={props.reference.image_alt}
              />
            ) : (
              <StyledCardMainImageMobile
                src={props.reference.image.localFile.publicURL}
                alt={props.reference.image_alt}
              />
            )}
          </StyledCardImageMobileContainer>
          <StyledTagsList>
            {props.reference.chips.map(chip => {
              return (
                <StyledTag key={uuidv4()} color={props.reference.chip_color}>
                  {chip.chip}
                </StyledTag>
              )
            })}
          </StyledTagsList>
          <StyledCustomerName type="copyBig">
            {props.reference.customer_name}
          </StyledCustomerName>
          <StyledShortDescription>
            <ReactMarkdown>{props.reference.short_description}</ReactMarkdown>
          </StyledShortDescription>
        </StyledCardText>
      </StyledMainContentContainer>
      <StyledExtraContentContainer>
        {props.reference.video_link && (
          <StyledReferenceVideo>
            <iframe
              width="375"
              height="212"
              src={props.reference.video_link}
              title={props.reference.video_title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </StyledReferenceVideo>
        )}
        {props.reference.testimonial && (
          <StyledTestimonialContainer>
            <StyledTestimonial type="quote" color={props.reference.chip_color}>
              <StyledQuotationMark>“</StyledQuotationMark>
              {props.reference.testimonial}
            </StyledTestimonial>
            <StyledTestimonialSource type="copyBig">
              {props.reference.testimonial_source}
            </StyledTestimonialSource>
          </StyledTestimonialContainer>
        )}
        <StyledLongTextContainer>
          <StyledTextAndImageContainer>
            <StyledTextContainer>
              {props.reference.long_description_1s.map(longDescription1 => {
                return (
                  <React.Fragment key={uuidv4()}>
                    <StyledTitle type="h6">
                      {longDescription1.title}
                      <br />
                    </StyledTitle>
                    <StyledText type="copyBig">
                      {longDescription1.text}
                    </StyledText>
                  </React.Fragment>
                )
              })}
            </StyledTextContainer>
            <StyledImageContainer>
              <StyledImage src={props.reference.image1.localFile.publicURL} />
            </StyledImageContainer>
          </StyledTextAndImageContainer>

          <StyledTextAndImageContainer>
            <StyledTextContainer>
              {props.reference.long_description_2s &&
                props.reference.long_description_2s.map(longDescription2 => {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <StyledTitle type="h6">
                        {longDescription2.title}
                        <br />
                      </StyledTitle>
                      <StyledText type="copyBig">
                        {longDescription2.text}
                      </StyledText>
                    </React.Fragment>
                  )
                })}
              {props.reference.long_description_3s &&
                props.reference.long_description_3s.map(longDescription3 => {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <StyledTitle type="h6">
                        {longDescription3.title}
                        <br />
                      </StyledTitle>
                      <StyledText type="copyBig">
                        {longDescription3.text}
                      </StyledText>
                    </React.Fragment>
                  )
                })}
            </StyledTextContainer>
            <StyledImageContainer>
              {props.reference.image2 && (
                <StyledImage src={props.reference.image2.localFile.publicURL} />
              )}
              {props.reference.image3 && (
                <StyledImage src={props.reference.image3.localFile.publicURL} />
              )}
            </StyledImageContainer>
          </StyledTextAndImageContainer>
        </StyledLongTextContainer>
      </StyledExtraContentContainer>
    </StyledReferenceCardContainer>
  )
}

const StyledReferenceCardContainer = styled.article`
  z-index: 90;
  background-color: ${({ theme }) => theme.palette.global.white};
  margin: 0;
  border-radius: 4rem;
`

const StyledMainContentContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 3rem 2.8rem 0;
`

const StyledCardText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
`

const StyledProductSlogan = styled(Typography)`
  padding: 2rem 0;
  font-size: 3.3rem;
  line-height: 4.3rem;
`

const StyledCustomerName = styled(Typography)`
  font-weight: 700;
  padding: 0 0 1rem;
`

const StyledProductName = styled(Typography)`
  padding: 0 0 1rem;
`
const StyledShortDescription = styled.div`
  padding: 0 0 1rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
`

const StyledCardImageMobileContainer = styled.div`
  width: 100%;
  align-self: center;
  background-color: ${({ theme }) => theme.palette.global.white};
`
const StyledCardMainImageMobile = styled.img`
  width: 100%;
  height: auto;
  padding: 2rem 0;
`

const StyledLogoImage = styled.img`
  width: 70%;
  max-width: 20rem;
  mix-blend-mode: multiply;
  :nth-child(even) {
    flex-direction: row-reverse;
  }
`

const StyledTagsList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-flow: wrap;
  padding: 0 0 2rem;
`

const StyledTag = styled.li<{ color: string }>`
  color: ${({ theme }) => theme.palette.global.white};
  margin: 1rem 1rem 0 0;
  padding: 0.4rem 1.2rem;
  border-radius: 2.4rem;
  background: ${({ color }) => color};
  font-size: 1.4rem;
  text-align: center;
`

const StyledExtraContentContainer = styled.div`
  margin: 5rem 0;
`

const StyledTestimonialContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
`

const StyledTestimonial = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color};
  padding: 0 0 1rem;
  font-weight: 700;
`

const StyledTestimonialSource = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.darkGrey};
  padding: 0 0 6rem;
  font-weight: 700;
`

const StyledQuotationMark = styled.span`
  font-size: 8rem;
  position: absolute;
  top: -3rem;
  left: -2rem;
`

const StyledLongTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.8rem 2rem;
`

const StyledTextAndImageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const StyledImageContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`
const StyledImage = styled.img`
  width: 100%;
`
const StyledTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`

const StyledTitle = styled(Typography)`
  font-weight: 700;
  padding: 2rem 0;
`

const StyledText = styled(Typography)``

const StyledReferenceVideo = styled.div`
  text-align: center;
  margin: 0 auto 10rem;
`