import styled from 'styled-components/macro'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { v4 as uuidv4 } from 'uuid'

import { ReferenceCardMainContentProps } from '../../../../../interfaces/references'
import Typography from '../../../../common/Typography'

export default function MainContentDesktop(
  props: ReferenceCardMainContentProps
): JSX.Element {
  const mainImageWidth = 472
  const mainImageHeight = 438
  return (
    <StyledMainContentWrapper
      rowDirection={props.reference.cardIndex % 2 === 0 ? 'row' : 'row-reverse'}
    >
      <StyledCardText>
        <StyledLogoContainer>
          <StyledLogoImage
            src={props.reference.customer_logo.localFile.publicURL}
            alt={props.reference.logo_alt}
          />
        </StyledLogoContainer>
        <StyledProductSlogan type="h4">
          {props.reference.product_slogan}
        </StyledProductSlogan>
        <StyledProductName type="overhead">
          {props.reference.product_name}
        </StyledProductName>
        <StyledTagsList>
          {props.reference.chips.map(chip => {
            return (
              <StyledTag key={uuidv4()} color={props.reference.chip_color}>
                {chip.chip}
              </StyledTag>
            )
          })}
        </StyledTagsList>
        <StyledCustomerName type="copyBig">
          {props.reference.customer_name}
        </StyledCustomerName>
        <StyledShortDescription>
          <ReactMarkdown>{props.reference.short_description}</ReactMarkdown>
        </StyledShortDescription>
        <br />
      </StyledCardText>
      <StyledCardImageDesktopContainer className="main-reference-image">
        <StyledCardImageDesktop
          src={props.reference.image.localFile.publicURL}
          alt={props.reference.image_alt}
          width={mainImageWidth}
          height={mainImageHeight}
          loading="lazy"
        />
      </StyledCardImageDesktopContainer>
    </StyledMainContentWrapper>
  )
}

const StyledMainContentWrapper = styled.div<{
  rowDirection: 'row' | 'row-reverse'
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ rowDirection }) => rowDirection};
  gap: 5.8rem;
`

const StyledCardText = styled.div`
  width: 50%;
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  justify-items: center;
  text-align: left;
`

const StyledCardImageDesktopContainer = styled.div`
  display: block;
  width: 50%;
  max-width: 47.2rem;
  align-self: center;
  background-color: transparent;
`
const StyledCardImageDesktop = styled.img<{
  width: number
  height: number
}>`
  width: 100%;
  height: auto;
  max-width: 47.2rem;
  aspect-ratio: ${({ width, height }) => width / height};
`

const StyledLogoContainer = styled.div`
  text-align: left;
`

const StyledLogoImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 25rem;
`

const StyledProductSlogan = styled(Typography)`
  padding: 5rem 0 3.8rem 0;
`

const StyledProductName = styled(Typography)`
  text-transform: uppercase;
  padding: 0 0 3.4rem;
`

const StyledTagsList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-flow: wrap;
  padding: 0 0 3rem;
`

const StyledTag = styled.li<{ color: string }>`
  color: ${({ theme }) => theme.palette.global.white};
  margin: 1rem 1rem 0 0;
  padding: 0.4rem 1.2rem;
  border-radius: 2.4rem;
  background: ${({ color }) => color};
  font-size: 1.4rem;
  text-align: center;
`

const StyledCustomerName = styled(Typography)`
  font-weight: 700;
  padding: 0 0 1rem;
`
const StyledShortDescription = styled.div`
  padding: 0 0 1rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
`
