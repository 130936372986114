import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { CustomerLogosQuery } from '../../../interfaces/references'
import useBreakpoint from '../../../hooks/useBreakpoint'
import CustomerLogo from './CustomerLogo'
import Typography from '../../common/Typography'
import Routes from '../../../interfaces/routes.d'

import ArrowDownIcon from '../../../assets/images/common/arrow_down.svg'
import ArrowUpIcon from '../../../assets/images/common/arrow_up.svg'

export default function CustomerLogos({ content }: CustomerLogosQuery) {
  const { t } = useTranslation()
  const [readMore, setReadMore] = useState(false)
  const linkName = readMore
    ? 'common.arrow_read_less'
    : 'common.arrow_read_more'
  const openReadMore = () => {
    if (!readMore) {
      setReadMore(true)
    } else setReadMore(false)
  }
  const isDesktop = useBreakpoint('sm')

  const sliceElementsNumber = isDesktop ? 3 : 2

  const CustomerLogosMainList = content
    .slice(0, sliceElementsNumber)
    .map(
      (logo: {
        node: { logo_image: { localFile: { publicURL: string } } }
      }) => (
        <CustomerLogo
          key={uuidv4()}
          logo={logo.node.logo_image.localFile.publicURL}
          altLogo="Alt"
        />
      )
    )

  const CustomerLogosAdditionalList = content
    .slice(sliceElementsNumber, content.length)
    .map(
      (logo: {
        node: { logo_image: { localFile: { publicURL: string } } }
      }) => (
        <CustomerLogo
          key={uuidv4()}
          logo={logo.node.logo_image.localFile.publicURL}
          altLogo="Alt"
        />
      )
    )

  return (
    <StyledCustomerLogosContainer id="logo-card">
      <StyledCustomerLogosWrapper>
        <StyledCustomerLogosTitle type="h7">
          {t('references.customer_logos.title')}
        </StyledCustomerLogosTitle>
        <StyledCustomerLogosTable>
          {CustomerLogosMainList}
        </StyledCustomerLogosTable>
        <StyledAdditionalLogosContainer id="discover-more-logos">
          <StyledDiscoverMoreLogos>
            <StyledCustomerLogosTable>
              {readMore && CustomerLogosAdditionalList}
            </StyledCustomerLogosTable>
            <StyledDiscoverMoreLink
              to={
                readMore
                  ? `${Routes.REFERENCES}#logo-card`
                  : `${Routes.REFERENCES}#discover-more-logos`
              }
              onAnchorLinkClick={openReadMore}
              arrowDirection={readMore ? 'arrowUp' : 'arrowDown'}
            >
              <StyledArrowImg
                src={readMore ? ArrowUpIcon : ArrowDownIcon}
                alt={readMore ? 'Discover Less Arrow' : 'Discover More Arrow'}
              />
              {t(linkName)}
            </StyledDiscoverMoreLink>
          </StyledDiscoverMoreLogos>
        </StyledAdditionalLogosContainer>
      </StyledCustomerLogosWrapper>
    </StyledCustomerLogosContainer>
  )
}

const StyledCustomerLogosContainer = styled.section`
  position: relative;
  width: 100%;
  border-radius: 4rem;
  text-align: center;
  margin: 1.6rem 0;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 2rem auto 0;
    padding: 0 4rem;
  }

  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 90%;
    padding: 0;
  }
`

const StyledCustomerLogosWrapper = styled.article`
  max-width: 112rem;
  width: 90%;
  margin: 1.6rem auto;
  box-shadow: 0 1.3rem 4rem 0.5rem rgba(116, 134, 169, 0.2);
  background-color: ${({ theme }) => theme.palette.global.white};
  border-radius: 4rem;
  padding: 3rem 1rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 100%;
    margin: 1.6rem auto;
    padding: 4rem 2rem 3rem;
  }
`

const StyledCustomerLogosTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.accent};
  padding: 0 2rem 1rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: 2rem 2rem;
  }
`
const StyledCustomerLogosTable = styled.div`
  padding: 0.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 0 2rem 2rem;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const StyledAdditionalLogosContainer = styled.div`
  margin: -3rem 0 0;
`

const StyledDiscoverMoreLogos = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const StyledDiscoverMoreLink = styled(AnchorLink)<{
  arrowDirection: 'arrowUp' | 'arrowDown'
}>`
  display: block;
  cursor: pointer;
  margin: 2rem auto;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
  transition: transform 0.5s ease-in-out;
  transform: translateY(0px);
  ${({ theme }) => theme.breakpoints.queries.sm} {
    :hover {
      img {
        animation-duration: 0.6s;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-name: ${({ arrowDirection }) =>
          arrowDirection === 'arrowUp'
            ? 'Arrow-Up-Animation'
            : ' Arrow-Down-Animation'};
      }
    }
  }
`
const StyledArrowImg = styled.img`
  margin: 0 auto;
  width: 1.2rem;
  padding: 0;
  position: relative;
  top: -2.6rem;
  left: 50%;
`
