import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'

import ExtraContentMobile from './ExtraContentMobile'
import { ReferenceCardModalProps } from '../../../../../interfaces/references'
import CloseIcon from '../../../../../assets/images/common/close_btn.svg'

export default function ExtraContentModal({
  hide,
  reference,
}: ReferenceCardModalProps) {
  return ReactDOM.createPortal(
    <StyledModalOverlay>
      <StyledModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
        <StyledModalHeader>
          <StyledModalCloseButtonWrapper onClick={hide}>
            <StyledModalCloseButton src={CloseIcon} alt="Close" />
          </StyledModalCloseButtonWrapper>
        </StyledModalHeader>
        <StyledModalContent>
          <ExtraContentMobile reference={reference} />
        </StyledModalContent>
      </StyledModalWrapper>
    </StyledModalOverlay>,
    document.body
  )
}

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  margin: 0 auto;
  overscroll-behavior: none;

  &.modal-transition-enter {
    transform: translateY(100%);
    opacity: 0;
  }
  &.modal-transition-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.4s;
    transition-property: transform, opacity;
  }
  &.modal-transition-exit {
    transform: translateY(0);
    opacity: 1;
  }
  &.modal-transition-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: all 0.4s;
    transition-property: transform, opacity;
  }
`
const StyledModalWrapper = styled.div`
  position: fixed;
  top: 2rem;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: transparent;
  border-radius: 4rem 4rem 0 0;
`

const StyledModalContent = styled.div`
  background: ${({ theme }) => theme.palette.global.white};
  position: relative;
  margin: 0 auto;
  border-radius: 4rem;
  max-width: 80rem;
`

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  position: fixed;
  height: 7rem;
  width: 100%;
  max-width: 80rem;
  border-radius: 4rem 4rem 0 0;
  margin: 1rem 0 0;
  z-index: 1;
  opacity: 0.98;
  pointer-events: none;
`

const StyledModalCloseButtonWrapper = styled.button`
  border: none;
  padding: 2rem;
  background: ${({ theme }) => theme.palette.global.white};
  font-weight: 300;
  line-height: 1;
  color: ${({ theme }) => theme.palette.global.darkGrey};
  opacity: 1;
  cursor: pointer;
  pointer-events: visible;
  border: none;
  position: fixed;
  background-color: transparent;
`
const StyledModalCloseButton = styled.img`
  padding: 1rem;
  border-radius: 50%;
  opacity: 0.9;
  background: ${({ theme }) => theme.palette.global.white};
`
