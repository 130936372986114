import React from 'react'
import { graphql } from 'gatsby'
import i18next from 'i18next'

import Layout from '../components/Layout/Layout'
import Hero from '../components/common/Hero/Hero'
import CustomerLogos from '../components/References/CustomerLogos/CustomerLogos'
import ReferenceCards from '../components/References/ReferenceCards/ReferenceCards'
import { ReferencesQuery } from '../interfaces/references'
import PlanetReference from '../components/common/Hero/PlanetReference'
import useBreakpoint from '../hooks/useBreakpoint'
import { HeroData } from '../interfaces/common'
import SEO from '../components/Layout/SEO/SEO'
import Routes from '../interfaces/routes.d'
import { useTranslation } from 'react-i18next'

export default function References({ data }: ReferencesQuery) {
  const isDesktop = useBreakpoint('sm')
  const locale = i18next.resolvedLanguage
  const cardList = data.allStrapiReferenceCards.edges.filter(
    card => card.node.locale === locale
  )
  // hook needed to rerender upon language change
  const { t } = useTranslation()

  const logoList = data.allStrapiLogos.edges

  const heroData: HeroData = {
    page: Routes.REFERENCES,
    title: 'references.hero.title',
    text: 'references.hero.text',
    arrowText: 'references.hero.arrow_text',
  }

  const PlanetWithIcons = (
    <PlanetReference
      scale={isDesktop ? [1, 1, 1] : [1.2, 1.2, 1.2]}
      position={isDesktop ? [0, 0, 0] : [7, -7, 0]}
      rotation={[0.0, -1.53, 0.5]}
    />
  )
  return (
    <>
      <SEO
        title="Pop Rocket | Referenzen, die begeistern"
        description="Aussagekräftige Cases mit namhaften Partnern: Von Ideation über Design bis zur finalen Umsetzung. Jetzt entdecken."
        tabTitle="Referenzen"
      />
      <Layout overflow="hidden">
        <Hero
          heroData={heroData}
          threeModel={PlanetWithIcons}
          left={isDesktop ? '20%' : '0'}
          top={isDesktop ? '-15%' : '0'}
          height={isDesktop ? '120rem' : '100rem'}
          overflow="hidden"
        />
        <ReferenceCards content={cardList} />
        <CustomerLogos content={logoList} />
      </Layout>
    </>
  )
}

export const referenceQuery = graphql`
  query ReferenceQuery {
    allStrapiReferenceCards(sort: { fields: cardIndex }) {
      edges {
        node {
          locale
          cardIndex
          image {
            localFile {
              publicURL
            }
          }
          short_description
          image_mobile {
            localFile {
              publicURL
            }
          }
          customer_logo {
            localFile {
              publicURL
            }
          }
          chip_color
          product_slogan
          product_name
          customer_name
          image_alt
          logo_alt
          chips {
            chip
          }
          testimonial
          testimonial_source
          video_link
          video_title
          image1 {
            localFile {
              publicURL
            }
          }
          long_description_1s {
            text
            title
            order
          }
          long_description_2s {
            text
            title
            order
          }
          long_description_3s {
            text
            title
            order
          }
          image2 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allStrapiLogos(sort: { fields: order }) {
      edges {
        node {
          id
          logo_image {
            localFile {
              publicURL
            }
          }
          order
          customer_name
        }
      }
    }
  }
`
