import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

import { DreiGLTF } from '../../../interfaces/three'
import { useFrame } from '@react-three/fiber'

export default function PlanetReference({
  ...props
}: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/models/glb/HeroPlanet.glb') as DreiGLTF

  const planetRef = useRef<THREE.Group>()
  useFrame(() => {
    if (planetRef.current) {
      planetRef.current.rotation.y += 0.001
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={planetRef}>
        <group name="Armature_Planet_01">
          <primitive object={nodes.Root_1} />
          <skinnedMesh
            name="Planet_L_1"
            frustumCulled={false}
            geometry={nodes.Planet_L_1.geometry}
            material={nodes.Planet_L_1.material}
            skeleton={nodes.Planet_L_1.skeleton}
          />
          <skinnedMesh
            name="Roads_R"
            frustumCulled={false}
            geometry={nodes.Roads_R.geometry}
            material={nodes.Roads_R.material}
            skeleton={nodes.Roads_R.skeleton}
          />
          <skinnedMesh
            name="StaticBuildings_R"
            frustumCulled={false}
            geometry={nodes.StaticBuildings_R.geometry}
            material={nodes.StaticBuildings_R.material}
            skeleton={nodes.StaticBuildings_R.skeleton}
          />
          <skinnedMesh
            name="Trees_R"
            frustumCulled={false}
            geometry={nodes.Trees_R.geometry}
            material={nodes.Trees_R.material}
            skeleton={nodes.Trees_R.skeleton}
          />
          <skinnedMesh
            name="Planet_R_1"
            frustumCulled={false}
            geometry={nodes.Planet_R_1.geometry}
            material={nodes.Planet_R_1.material}
            skeleton={nodes.Planet_R_1.skeleton}
          />
          <skinnedMesh
            name="Roads_L"
            frustumCulled={false}
            geometry={nodes.Roads_L.geometry}
            material={nodes.Roads_L.material}
            skeleton={nodes.Roads_L.skeleton}
          />
          <skinnedMesh
            name="Rocket"
            frustumCulled={false}
            geometry={nodes.Rocket.geometry}
            material={materials.M_Rocket}
            skeleton={nodes.Rocket.skeleton}
          />
          <skinnedMesh
            name="Rocket_Holder"
            frustumCulled={false}
            geometry={nodes.Rocket_Holder.geometry}
            material={materials.M_MechanicalArm}
            skeleton={nodes.Rocket_Holder.skeleton}
          />
          <skinnedMesh
            name="RocketWindows"
            frustumCulled={false}
            geometry={nodes.RocketWindows.geometry}
            material={materials.M_RocketWindow}
            skeleton={nodes.RocketWindows.skeleton}
          />
          <skinnedMesh
            name="StaticBuildings_L"
            frustumCulled={false}
            geometry={nodes.StaticBuildings_L.geometry}
            material={nodes.StaticBuildings_L.material}
            skeleton={nodes.StaticBuildings_L.skeleton}
          />
          <skinnedMesh
            name="Trees_L"
            frustumCulled={false}
            geometry={nodes.Trees_L.geometry}
            material={nodes.Trees_L.material}
            skeleton={nodes.Trees_L.skeleton}
          />
        </group>
        <group name="Armature_Planet_02">
          <primitive object={nodes.Root_02} />
        </group>

        <group
          name="Armature_Laptop006"
          position={[7.87, 10.56, -5.87]}
          rotation={[1.58, 0.4, -2.49]}
          scale={0.52}
        >
          <primitive object={nodes.Base000_1} />
          <group name="Laptop006">
            <skinnedMesh
              name="Plane021"
              frustumCulled={false}
              geometry={nodes.Plane021.geometry}
              material={nodes.Plane021.material}
              skeleton={nodes.Plane021.skeleton}
            />
            <skinnedMesh
              name="Plane021_1"
              frustumCulled={false}
              geometry={nodes.Plane021_1.geometry}
              material={nodes.Plane021_1.material}
              skeleton={nodes.Plane021_1.skeleton}
            />
          </group>
        </group>
        <group
          name="Armature_Laptop005"
          position={[7.73, -3.54, -13.59]}
          rotation={[0.54, 0.32, -1.61]}
          scale={0.7}
        >
          <primitive object={nodes.Base000_2} />
          <group name="Laptop005">
            <skinnedMesh
              name="Plane020"
              frustumCulled={false}
              geometry={nodes.Plane020.geometry}
              material={nodes.Plane020.material}
              skeleton={nodes.Plane020.skeleton}
            />
            <skinnedMesh
              name="Plane020_1"
              frustumCulled={false}
              geometry={nodes.Plane020_1.geometry}
              material={nodes.Plane020_1.material}
              skeleton={nodes.Plane020_1.skeleton}
            />
          </group>
        </group>
        <group
          name="Armature_Laptop004"
          position={[0.45, 14.28, 12.39]}
          rotation={[2.32, 0.52, -1.46]}
          scale={0.7}
        >
          <primitive object={nodes.Base000_3} />
          <group name="Laptop004">
            <skinnedMesh
              name="Plane019"
              frustumCulled={false}
              geometry={nodes.Plane019.geometry}
              material={nodes.Plane019.material}
              skeleton={nodes.Plane019.skeleton}
            />
            <skinnedMesh
              name="Plane019_1"
              frustumCulled={false}
              geometry={nodes.Plane019_1.geometry}
              material={nodes.Plane019_1.material}
              skeleton={nodes.Plane019_1.skeleton}
            />
          </group>
        </group>
        <group
          name="Armature_PiePhone014"
          position={[3.58, -2.31, 12.42]}
          rotation={[0.5, 1.44, 0.23]}
        >
          <primitive object={nodes.Bone_1} />
          <skinnedMesh
            name="PiePhone014"
            frustumCulled={false}
            geometry={nodes.PiePhone014.geometry}
            material={nodes.PiePhone014.material}
            skeleton={nodes.PiePhone014.skeleton}
          />
        </group>
        <group
          name="Armature_PiePhone013"
          position={[13.45, 1.14, 6.45]}
          rotation={[1.38, 0.89, -1.42]}
        >
          <primitive object={nodes.Bone_2} />
          <skinnedMesh
            name="PiePhone013"
            frustumCulled={false}
            geometry={nodes.PiePhone013.geometry}
            material={nodes.PiePhone013.material}
            skeleton={nodes.PiePhone013.skeleton}
          />
        </group>
        <group
          name="Armature_PiePhone012"
          position={[7.55, 5.09, -10.27]}
          rotation={[1.3, 1.18, -0.61]}
        >
          <primitive object={nodes.Bone_3} />
          <skinnedMesh
            name="PiePhone012"
            frustumCulled={false}
            geometry={nodes.PiePhone012.geometry}
            material={nodes.PiePhone012.material}
            skeleton={nodes.PiePhone012.skeleton}
          />
        </group>
        <group
          name="Armature_PiePhone011"
          position={[12.09, -2.42, -10.37]}
          rotation={[-0.25, 1.1, -1.13]}
          scale={0.82}
        >
          <primitive object={nodes.Bone_4} />
          <skinnedMesh
            name="PiePhone011"
            frustumCulled={false}
            geometry={nodes.PiePhone011.geometry}
            material={nodes.PiePhone011.material}
            skeleton={nodes.PiePhone011.skeleton}
          />
        </group>
        <group
          name="Armature_PiePhone010"
          position={[12.19, -0.9, -5.74]}
          rotation={[2.35, 0.74, -2.53]}
        >
          <primitive object={nodes.Bone_5} />
          <skinnedMesh
            name="PiePhone010"
            frustumCulled={false}
            geometry={nodes.PiePhone010.geometry}
            material={nodes.PiePhone010.material}
            skeleton={nodes.PiePhone010.skeleton}
          />
        </group>
        <group
          name="Armature_PiePhone009"
          position={[2.55, 16.04, 1.27]}
          rotation={[-2.66, 1.3, -2.99]}
        >
          <primitive object={nodes.Bone_6} />
          <skinnedMesh
            name="PiePhone009"
            frustumCulled={false}
            geometry={nodes.PiePhone009.geometry}
            material={nodes.PiePhone009.material}
            skeleton={nodes.PiePhone009.skeleton}
          />
        </group>
        <group
          name="Armature_PiePhone008"
          position={[8.46, 9.82, 2.36]}
          rotation={[0.45, 0.89, -0.67]}
        >
          <primitive object={nodes.Bone_7} />
          <skinnedMesh
            name="PiePhone008"
            frustumCulled={false}
            geometry={nodes.PiePhone008.geometry}
            material={nodes.PiePhone008.material}
            skeleton={nodes.PiePhone008.skeleton}
          />
        </group>
      </group>
    </group>
  )
}
