import React, { ReactElement } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'

import HeroScene from './HeroScene'
import Typography from '../Typography'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { HeroData } from '../../../interfaces/common'

import ArrowDownIcon from '../../../assets/images/common/arrow_down.svg'

interface HeroDataType {
  heroData: HeroData
  boldCopies?: string[]
  threeModel: JSX.Element
  left?: string
  top?: string
  overflow?: string
  height: string
}

Hero.defaultProps = {
  top: '-50%',
  overflow: 'unset',
  left: 'initial',
}

export default function Hero({
  heroData,
  boldCopies,
  threeModel,
  left,
  top,
  overflow,
  height,
}: HeroDataType): ReactElement {
  const { t } = useTranslation()
  const isDesktop = useBreakpoint('sm')

  function renderBoldCopies(): ReactElement {
    return (
      <>
        {boldCopies?.map((boldCopy: string) => (
          <StyledBoldCopy key={uuidv4()} type="copyBig">
            {t(boldCopy)}
          </StyledBoldCopy>
        ))}
      </>
    )
  }

  return (
    <StyledHeroContainer overflow={overflow}>
      <StyledTextContainer>
        <StyledHeroTitle type={isDesktop ? 'h1' : 'h1Mobile'}>
          {t(heroData.title)}
          <br />
          {heroData.title2 ? t(heroData.title2) : null}
        </StyledHeroTitle>
        <Typography type="copyBig">{t(heroData.text)}</Typography>
        <StyledBoldCopyContainer>{renderBoldCopies()}</StyledBoldCopyContainer>
      </StyledTextContainer>
      <HeroScene
        threeModel={threeModel}
        left={left}
        top={top}
        height={height}
      />
      <StyledDiscoverMore to={`${heroData.page}#main-content`}>
        <StyledArrowDownImg src={ArrowDownIcon} alt="Discover More Arrow" />
        <StyledArrowText type="copy">{t(heroData.arrowText)}</StyledArrowText>
      </StyledDiscoverMore>
    </StyledHeroContainer>
  )
}

const StyledHeroContainer = styled.section<{ overflow: string | undefined }>`
  width: 100%;
  position: relative;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    flex-direction: row;
    padding: 0rem;
    height: 100%;
    width: 100%;
  }
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  text-align: left;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 40%;
    max-width: 33rem;
    margin: 5rem 0rem 0rem 6rem;
    font-size: 1.4rem;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    margin: 5rem 0rem 0rem 12rem;
  }
`
const StyledDiscoverMore = styled(AnchorLink)`
  position: relative;
  margin: 3rem 0 0;
  @media (max-height: 630px) {
    bottom: 0rem;
  }

  ${({ theme }) => theme.breakpoints.queries.sm} {
    display: none;
  }
`

const StyledArrowDownImg = styled.img`
  margin: 0rem 0rem 1rem 0rem;
  animation-duration: 1.2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-name: Arrow-Down-Animation;
  animation-iteration-count: infinite;
`
const StyledHeroTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.accent};
  padding: 0 0 1.9rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: 0 0 3.2rem;
  }
`

const StyledArrowText = styled(Typography)`
  font-weight: 700;
`

const StyledBoldCopyContainer = styled.div`
  margin: 1rem 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 3rem 0;
  }
`
const StyledBoldCopy = styled(Typography)`
  font-weight: 700;
`
