import styled from 'styled-components/macro'
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { gsap } from 'gsap'
import { useTranslation } from 'react-i18next'

import useModal from '../../../../../hooks/useModal'
import MainContentMobile from './MainContentMobile'
import ExtraContentModal from './ExtraContentModal'
import { ReferenceCardPropsMobile } from '../../../../../interfaces/references'
import OverlayModal from './OverlayModal'

export default function ReferenceCardMobile(props: ReferenceCardPropsMobile) {
  const { t } = useTranslation()

  //Modal for mobile devices
  const { isShowing, toggle } = useModal()
  const duration = 300

  useEffect(() => {
    //Parallax Card Image Animation

    const cardsMobile: HTMLDivElement[] = Array.from(
      document.querySelectorAll<HTMLDivElement>('.card-mobile')
    )

    const moveOptionsMobile = {
      rootMargin: '0px',
      threshold: 0.5,
    }

    cardsMobile.forEach(el => {
      const imageMobile = el.querySelector('.main-reference-image-mobile')

      const tl = gsap.timeline({ paused: true })
      tl.to(imageMobile, {
        y: -20,
        ease: 'power1.inOut',
      }).to(imageMobile, {
        y: 20,
        ease: 'power1.inOut',
      })

      const observer = new IntersectionObserver(entry => {
        if (entry[0].intersectionRatio > 0) {
          gsap.ticker.add(progressTween)
        } else {
          gsap.ticker.remove(progressTween)
        }
      }, moveOptionsMobile)

      const progressTween = () => {
        const scrollPosition = window.scrollY + window.innerHeight
        const elPosition = scrollPosition - el.offsetTop
        const durationDistance = window.innerHeight + el.offsetHeight
        const currentProgress = elPosition / durationDistance
        tl.progress(currentProgress)
      }
      observer.observe(el)
    })
  })
  useEffect(() => {
    if (isShowing) {
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.touchAction = ''
    }
  }, [isShowing])

  return (
    <>
      <StyledReferenceCardContainer className="card-mobile">
        <MainContentMobile reference={props.reference} activeIndex={null} />
        <StyledDiscoverMoreContainer className="discover-more-container">
          <StyledDiscoverMore>
            <CSSTransition
              in={isShowing}
              className="modal-transition"
              classNames="modal-transition"
              timeout={duration}
              unmountOnExit
            >
              <ExtraContentModal reference={props.reference} hide={toggle} />
            </CSSTransition>
            <CSSTransition
              in={isShowing}
              className="overlay-transition"
              classNames="overlay-transition"
              timeout={duration}
              unmountOnExit
            >
              <OverlayModal />
            </CSSTransition>

            <StyledReadMoreLinkMobile
              color={props.reference.chip_color}
              onClick={toggle}
            >
              {t('common.arrow_read_more')}
            </StyledReadMoreLinkMobile>
          </StyledDiscoverMore>
        </StyledDiscoverMoreContainer>
      </StyledReferenceCardContainer>
      <StyledDivider />
    </>
  )
}

const StyledReferenceCardContainer = styled.article`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.global.grey};
  border-radius: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
`

const StyledDiscoverMoreContainer = styled.div`
  margin: 0;
`

const StyledDiscoverMore = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

const StyledReadMoreLinkMobile = styled.a<{ color: string }>`
  display: block;
  cursor: pointer;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: underline;
  color: ${({ color }) => color};

  svg {
    margin: 0 0 0 2rem;
  }
`

const StyledDivider = styled.hr`
  width: 90%;
  height: 0.2rem;
  color: ${({ theme }) => theme.palette.global.divider};
`
