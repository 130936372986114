import styled from 'styled-components/macro'
import React, { useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { gsap } from 'gsap'
import { useTranslation } from 'react-i18next'

import { ReferenceCardProps } from '../../../../../interfaces/references'
import Typography from '../../../../common/Typography'
import ExtraContentDesktop from './ExtraContentDesktop'
import MainContentDesktop from './MainContentDesktop'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Routes from '../../../../../interfaces/routes.d'

import ArrowDownIcon from '../../../../../assets/images/common/arrow_down.svg'
import ArrowUpIcon from '../../../../../assets/images/common/arrow_up.svg'

export default function ReferenceCard(props: ReferenceCardProps) {
  const { t } = useTranslation()

  const defaultHeight = 0
  const [contentHeight, setContentHeight] = useState<number>(defaultHeight)
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  const [isExtended, setIsExtended] = useState<number>(-1)
  const toggleEntry = (id: number): void => {
    setIsExtended(isExtended !== id ? id : -1)
  }

  useEffect(() => {
    setContentHeight(height)
    window.addEventListener('resize', () => {
      setContentHeight(height)
    })
    return window.removeEventListener('resize', () => {
      setContentHeight(height)
    })
  }, [height])

  const linkName =
    isExtended === props.cardIndex
      ? 'common.arrow_read_less'
      : 'common.arrow_read_more'

  const renderContentHeightInRem = (): string =>
    `${(contentHeight / 10).toString()}rem`

  //Parallax Card Image Animation
  const cards: HTMLDivElement[] = Array.from(
    document.querySelectorAll<HTMLDivElement>('.card')
  )

  const moveOptions = {
    rootMargin: '0px',
    threshold: 0,
  }

  cards.forEach(el => {
    const image = el.querySelector('.main-reference-image')

    const tl = gsap.timeline({ paused: true })
    tl.to(image, {
      y: -30,
      ease: 'power1.inOut',
    }).to(image, {
      y: 30,
      ease: 'power1.inOut',
    })

    const observer = new IntersectionObserver(entry => {
      if (entry[0].intersectionRatio > 0) {
        gsap.ticker.add(progressTween)
      } else {
        gsap.ticker.remove(progressTween)
      }
    }, moveOptions)

    const progressTween = () => {
      const scrollPosition = window.scrollY + window.innerHeight
      const elPosition = scrollPosition - el.offsetTop
      const durationDistance = window.innerHeight + el.offsetHeight
      const currentProgress = elPosition / durationDistance
      tl.progress(currentProgress)
    }
    observer.observe(el)
  })

  return (
    <StyledReferenceCardWrapper className="card">
      <StyledMainContentContainer
        isExtended={isExtended === props.cardIndex}
        id={`card-${props.cardIndex}-main-content`}
      >
        <MainContentDesktop
          isExtended={isExtended === props.cardIndex}
          reference={props.reference}
        />
      </StyledMainContentContainer>
      <StyledExtraContentContainer
        maxHeight={renderContentHeightInRem()}
        isExtended={isExtended === props.cardIndex}
        id={`card-${props.cardIndex}-extra-content`}
      >
        <StyledExtraContent ref={ref}>
          <ExtraContentDesktop reference={props.reference} />
        </StyledExtraContent>
      </StyledExtraContentContainer>
      <StyledDiscoverMoreLinkContainer
        arrowDirection={
          isExtended === props.cardIndex ? 'arrowUp' : 'arrowDown'
        }
        onAnchorLinkClick={() => toggleEntry(props.cardIndex)}
        to={
          isExtended === props.cardIndex
            ? `${Routes.REFERENCES}#card-${props.cardIndex}-main-content`
            : `${Routes.REFERENCES}#card-${props.cardIndex}-extra-content`
        }
      >
        <StyledArrowImg
          src={isExtended === props.cardIndex ? ArrowUpIcon : ArrowDownIcon}
          alt={
            isExtended === props.cardIndex
              ? 'Discover Less Arrow'
              : 'Discover More Arrow'
          }
        />
        <StyledDiscoverMoreLink type="copy">
          {t(linkName)}
        </StyledDiscoverMoreLink>
      </StyledDiscoverMoreLinkContainer>
    </StyledReferenceCardWrapper>
  )
}

const StyledReferenceCardWrapper = styled.div`
  margin: 2rem 0;
  box-shadow: 0 1.3rem 4rem 0.5rem rgba(116, 134, 169, 0.2);
  padding: 4rem 7rem 3rem;
  border-radius: 4rem;
  max-width: 112rem;
  background-color: ${({ theme }) => theme.palette.global.white};
`
const StyledMainContentContainer = styled.div<{ isExtended: boolean }>`
  position: relative;
  width: 100%;
  border-bottom: 0.1rem solid ${({ theme }) => theme.palette.global.white};
  transition: padding 0.5s ease-in, background-color 0.5s ease-in;
  background-color: ${({ theme }) => theme.palette.global.white};
`

const StyledExtraContentContainer = styled.div<{
  isExtended: boolean
  maxHeight: string
}>`
  position: relative;
  max-height: ${({ isExtended, maxHeight }) =>
    isExtended ? maxHeight : '0rem'};
  background-color: ${({ isExtended, theme }) =>
    isExtended ? theme.palette.global.white : theme.palette.global.white};
  transition: max-height 0.5s ease-in, background-color 0.5s ease-in;
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`

const StyledExtraContent = styled.div`
  box-sizing: border-box;
`

const StyledArrowImg = styled.img`
  margin: 0 auto;
  padding: 0 0 1.5rem;
  width: 1.2rem;
`
const StyledDiscoverMoreLinkContainer = styled(AnchorLink)<{
  arrowDirection: 'arrowUp' | 'arrowDown'
}>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  :hover {
    img {
      animation-duration: 0.6s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      animation-name: ${({ arrowDirection }) =>
        arrowDirection === 'arrowUp'
          ? 'Arrow-Up-Animation'
          : ' Arrow-Down-Animation'};
    }
  }
`

const StyledDiscoverMoreLink = styled(Typography)`
  text-decoration: underline;
  font-weight: 700;
`
