import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'

export default function OverlayModal() {
  return ReactDOM.createPortal(
    <StyledOverlay />,

    document.body
  )
}

const StyledOverlay = styled.div`
  background-color: ${({ theme }) => theme.palette.global.overlay};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 98;
  opacity: 0.6;
  outline: 0;

  &.overlay-transition-enter {
    opacity: 0;
  }
  &.overlay-transition-enter-active {
    transition: opacity 0.5s;
    opacity: 0.6;
  }
  &.overlay-transition-exit {
    opacity: 0.6;
  }
  &.overlay-transition-exit-active {
    transition: opacity 0.5s;
    opacity: 0;
  }
`
