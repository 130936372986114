import styled from 'styled-components/macro'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { v4 as uuidv4 } from 'uuid'

import { ReferenceCardPropsMobile } from '../../../../../interfaces/references'
import Typography from '../../../../common/Typography'
import useBreakpoint from '../../../../../hooks/useBreakpoint'

export default function MainContent(
  props: ReferenceCardPropsMobile
): JSX.Element {
  const isDesktop = useBreakpoint('sm')
  return (
    <StyledMainContentContainer>
      <StyledCardText>
        <StyledLogoContainer>
          <StyledLogoImage
            src={props.reference.customer_logo.localFile.publicURL}
            alt={props.reference.logo_alt}
          />
        </StyledLogoContainer>
        <StyledProductSlogan type={isDesktop ? 'h4' : 'h3'}>
          {props.reference.product_slogan}
        </StyledProductSlogan>
        <StyledProductName type="overhead">
          {props.reference.product_name}
        </StyledProductName>
        <StyledCardImageMobileContainer>
          {props.reference.image_mobile ? (
            <StyledCardImageMobile
              className="main-reference-image-mobile"
              src={props.reference.image_mobile.localFile.publicURL}
              alt={props.reference.image_alt}
            />
          ) : (
            <StyledCardImageMobile
              className="main-reference-image-mobile"
              src={props.reference.image.localFile.publicURL}
              alt={props.reference.image_alt}
            />
          )}
        </StyledCardImageMobileContainer>
        <StyledTagsList>
          {props.reference.chips.map(chip => {
            return (
              <StyledTag key={uuidv4()} color={props.reference.chip_color}>
                {chip.chip}
              </StyledTag>
            )
          })}
        </StyledTagsList>
        <StyledCustomerName type="copyBig">
          {props.reference.customer_name}
        </StyledCustomerName>
        <StyledShortDescription>
          <ReactMarkdown>{props.reference.short_description}</ReactMarkdown>
        </StyledShortDescription>
      </StyledCardText>
    </StyledMainContentContainer>
  )
}

const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledCardText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  justify-items: center;

  p {
    max-width: 100%;
  }
`

const StyledCardImageMobileContainer = styled.div`
  width: 100%;
  align-self: center;
  background-color: ${({ theme }) => theme.palette.global.white};
  box-shadow: 0 1.3rem 4rem 0.5rem rgba(116, 134, 169, 0.2);
  border-radius: 4rem;
  margin: 0 0 2rem;
`
const StyledCardImageMobile = styled.img`
  width: 100%;
  height: auto;
  padding: 2.5rem;
`
const StyledLogoContainer = styled.div`
  text-align: left;
`

const StyledLogoImage = styled.img`
  width: 70%;
  max-width: 20rem;
  mix-blend-mode: multiply;
`

const StyledProductSlogan = styled(Typography)`
  padding: 1rem 0 1rem;
  font-size: 3.3rem;
  line-height: 4.3rem;
`

const StyledProductName = styled(Typography)`
  padding: 0 0 1rem;
`

const StyledTagsList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-flow: wrap;
  padding: 0 0 2rem;
`

const StyledTag = styled.li<{ color: string }>`
  color: ${({ theme }) => theme.palette.global.white};
  margin: 1rem 1rem 0 0;
  padding: 0.4rem 1.2rem;
  border-radius: 2.4rem;
  background: ${({ color }) => color};
  font-size: 1.4rem;
  text-align: center;
`

const StyledCustomerName = styled(Typography)`
  font-weight: 700;
  padding: 0 0 1rem;
`
const StyledShortDescription = styled.div`
  padding: 0 0 3rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
`
