import React, { useState } from 'react'
import styled from 'styled-components/macro'

import ReferenceCardDesktop from './ReferenceCard/ReferenceCardDesktop/ReferenceCardDesktop'
import { ReferenceCardsProps, Content } from '../../../interfaces/references'
import useBreakpoint from '../../../hooks/useBreakpoint'
import ReferenceCardMobile from './ReferenceCard/ReferenceCardMobile/ReferenceCardMobile'

export default function ReferenceCards({
  content,
}: ReferenceCardsProps): JSX.Element {
  const isDesktop = useBreakpoint('sm')
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  return (
    <StyledReferenceCardsContainer id="main-content">
      {content.map((referenceCard: { node: Content }) =>
        isDesktop ? (
          <ReferenceCardDesktop
            key={referenceCard.node.cardIndex}
            reference={referenceCard.node}
            cardIndex={referenceCard.node.cardIndex}
          />
        ) : (
          <ReferenceCardMobile
            key={referenceCard.node.cardIndex}
            onClick={() => {
              if (activeIndex === referenceCard.node.cardIndex) {
                setActiveIndex(null)
              } else {
                setActiveIndex(referenceCard.node.cardIndex)
              }
            }}
            reference={referenceCard.node}
            cardIndex={referenceCard.node.cardIndex}
            activeIndex={activeIndex}
          />
        )
      )}
    </StyledReferenceCardsContainer>
  )
}

const StyledReferenceCardsContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 16rem auto 0;
    padding: 0 4rem;
  }
  ${({ theme }) => theme.breakpoints.queries.lg} {
    width: 90%;
    padding: 0;
  }
`
