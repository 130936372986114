import styled from 'styled-components/macro'
import React from 'react'

interface LogoProps {
  logo: string
  altLogo: string
}

export default function CustomerLogo(props: LogoProps): JSX.Element {
  return <StyledCustomerLogoItem src={props.logo} alt={props.altLogo} />
}

const StyledCustomerLogoItem = styled.img`
  width: auto;
  max-width: 30rem;
  height: 3.1rem;
  margin: 2rem 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 3rem 0;
    height: 4rem;
  }

  ${({ theme }) => theme.breakpoints.queries.md} {
    height: 6.2rem;
  }
`
